<template>
  <div>
    <div class="misc-wrapper">
      <div class="misc-inner pt-2 pb-3 pt-sm-2 pb-sm-3">
        <div class="w-100 text-center">
          <h2 class="display-4 mb-1">
            Terima kasih sudah mengunjungi SIA, silahkan mengupdate biodata dan mengajukan permohonan
          </h2>
          <!-- <p class="mb-3">
            We're creating something awesome. Please subscribe to get notified
            when it's ready!
          </p> -->
        </div>
      </div>
    </div>
    <section id="knowledge-base-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col md="6" sm="6" class="kb-search-content">
          <b-card
            class="text-center cursor-pointer"
            :img-src="require('@/assets/images/illustration/api.svg')"
            img-top
            @click="$router.push({ name: 'mandiri-biodata' })"
          >
            <h4>Biodata</h4>
            <b-card-text class="mt-1">
              Lengkapi Biodata Anda
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" sm="6" class="kb-search-content">
          <b-card
            class="text-center cursor-pointer"
            :img-src="require('@/assets/images/illustration/marketing.svg')"
            img-top
            @click="$router.push({ name: 'mandiri-list-permohonan' })"
          >
            <h4>Permohonan</h4>
            <b-card-text class="mt-1">
              Ajukan permohonan verifikasi atau data ulang
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  // BButton,
  // BImg,
  // BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    // BButton,
    // BImg,
    // BAvatar,
  },
  directives: {
    Ripple,
  },
  // data() {
  //   return {
  //     testImg: require('@/assets/images/illustration/sales.svg'),
  //   }
  // },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (
      userData.default_role !== 'user' &&
      userData.default_role !== 'member'
    ) {
      this.$router.replace({ name: 'biodata-list' })
    }
  },
  methods: {
    goToBiodata() {
      this.$router.push({ name: 'mandiri-biodata' })
    },
    goToPermohonan() {
      this.$router.push({ name: 'mandiri-list-permohonan' })
    },
  },
}
</script>

<style></style>
